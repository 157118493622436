import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Card, CardBody, Col, Container, Row,
  Input
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DataTable from 'react-data-table-component';
import { useQuery, gql } from '@apollo/client';
import ReactStars from 'react-rating-stars-component';

const REVIEWS_QUERY = gql`
query MyQuery($_eq: Int = 10) {
  reviews(where: {author_user_id: {_eq: $_eq}}, order_by: {date: desc}) {
    date
    id
    comment
    rating
    service_provider {
      username
    }
    service_provider_id
  }
}
`;

const Reviews = ({customer_user_id}) => {
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // const serviceProviderId = parseInt(localStorage.getItem("opID"));

  const { loading, error, data } = useQuery(REVIEWS_QUERY, {
    variables: { "_eq":customer_user_id },
    fetchPolicy: 'network-only', // Use network-only to always fetch the latest data
    onCompleted: (data) => {
      if (data && data.reviews) {
        console.log("REVIEWS",data)
        setFilteredData(data.reviews); // Initially no filter
      }
    },
    onError: (error) => {
      console.error("Error fetching reviews:", error.message);
    }
  });

  useEffect(() => {
    if (data && data.reviews) {
      setFilteredData(data.reviews);
    }
  }, [data]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = data.reviews.filter(review => 
      review.author.username.toLowerCase().includes(value) ||
      review.comment.toLowerCase().includes(value) ||
      review.rating.toString().includes(value)
    );
    setFilteredData(filtered);
  };

  const columns = [
    {
      name: t("Provider"),
      sortable: true,
      sortFunction: (a, b) => a.service_provider.username.localeCompare(b.service_provider.username),
      selector: row => row.service_provider.username,
    },
    {
      name: t("Date"),
      selector: row => new Date(row.date).toLocaleString(),
      sortable: true,
    },
    {
      name: t("Comment"),
      selector: row => row.comment,
      sortable: true,
    },
    {
      name: t("Rating"),
      sortFunction: (a, b) => a.rating>=(b.rating),
      selector: row => (
        <ReactStars
          count={5}
          value={row.rating}
          edit={false}
          size={24}
          activeColor="#ffd700"
        />
      ),
      sortable: true,
    }
  ];

  return (
    <React.Fragment>
        <Container fluid>
          <Card>
            <CardBody>

              <Row className="g-4 mb-3">
                <Col md="3"></Col>
                <Col md="3"></Col>
                <Col md="6">
                  <div className="d-flex justify-content-md-end">
                    <div className="search-box sm-12" style={{ "width": "300px" }}>
                      <input 
                        type="text" 
                        className="form-control search" 
                        placeholder={t("Search...")} 
                        value={searchTerm} 
                        onChange={handleSearch} 
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </Col>
              </Row>
              <DataTable
                columns={columns}
                data={filteredData}
                progressPending={loading}
                pagination
                highlightOnHover
              />
            </CardBody>
          </Card>
        </Container>
    </React.Fragment>
  );
};

export default Reviews;
