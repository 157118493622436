import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//Import Components
import VerticalLayout from "./VerticalLayouts";
// import TwoColumnLayout from "./TwoColumnLayout";
import { Container } from "reactstrap";
// import HorizontalLayout from "./HorizontalLayout";
import { ReactComponent as LogoSvg } from "./logo.svg"; // Import your SVG file

const Sidebar = ({ layoutType }) => {

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
    } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    } else {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    }
  };
//3a3f51
  return (
    <React.Fragment>
      <div className="app-menu navbar-menu" style={{"backgroundColor":"#080721"}}>
        <div className="navbar-brand-box"  style={{marginTop:"-50px",marginBottom:"-70px","backgroundColor":"#080721"}}>
              {/* <span><h3>ADMIN</h3></span> */}
          <Link to="/" className="logo logo-dark">
            {/* <span className="logo-sm">
              <img src={logoSm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span> */}
<span className="logo-sm">
  <h3 style={{ color: "white" }}>
    {/* <i className="ri-scissors-line"></i> */}
  </h3>
</span>
<span className="logo-lg">
  <h3 style={{ color: "white" }}>
    {/* <i className="ri-scissors-line"></i>PLANMATEe */}
  </h3>
</span>

          </Link>

          <Link to="/" className="logo logo-light">
          {/* <span><h3  style={{"color": "white"}}><i className=" ri-bit-coin-fill"></i>ADMIN</h3></span> */}
          <span className="logo-sm">
  <h3 style={{ color: "white" }}>
    {/* <i className="ri-scissors-line"></i> */}
  </h3>
</span>
<span className="logo-lg" >
  {/* <h3 style={{ color: "white" }}> */}
    <LogoSvg style={{ height: "200px", fill: "white" }} /> 
  {/* </h3> */}
</span>
            {/* <span className="logo-sm">
              <img src={logoSm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLight} alt="" height="17" />
            </span> */}
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
            style={{"backgroundColor":"#080721"}}
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>

          <React.Fragment>
            <SimpleBar id="scrollbar"  className="h-100" style={{"backgroundColor":"#080721"}}>
              <Container fluid>
                <div id="two-column-menu"></div>
                <ul className="navbar-nav" id="navbar-nav">
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
            <div className="sidebar-background" style={{"backgroundColor":"#080721"}}></div>
          </React.Fragment>
      
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
