import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {isAllowedToCreateOperator} from "../Components/Common/functions";
const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isApps2, setIsApps2] = useState(false);
    const [isAppsMarketing, setIsAppsMarketing] = useState(false);
    const [isAppsAcap,setIsAppsAcap] = useState(false);

   
    // const [isAuth, setIsAuth] = useState(false);
    // const [isPages, setIsPages] = useState(false);
    // const [isBaseUi, setIsBaseUi] = useState(false);
    // const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    // const [isForms, setIsForms] = useState(false);
    // const [isTables, setIsTables] = useState(false);
    // const [isCharts, setIsCharts] = useState(false);
    // const [isIcons, setIsIcons] = useState(false);
    // const [isMaps, setIsMaps] = useState(false);
    // const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Apps
    // const [isEmail, setEmail] = useState(false);
    // const [isSubEmail, setSubEmail] = useState(false);
    // const [isEcommerce, setIsEcommerce] = useState(false);
    // const [isProjects, setIsProjects] = useState(false);
    // const [isTasks, setIsTasks] = useState(false);
    // const [isCRM, setIsCRM] = useState(false);
    // const [isCrypto, setIsCrypto] = useState(false);
    // const [isInvoices, setIsInvoices] = useState(false);
    // const [isSupportTickets, setIsSupportTickets] = useState(false);
    // const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
    // const [isJobs, setIsJobs] = useState(false);
    // const [isJobList, setIsJobList] = useState(false);
    // const [isCandidateList, setIsCandidateList] = useState(false);


    // // Authentication
    // const [isSignIn, setIsSignIn] = useState(false);
    // const [isSignUp, setIsSignUp] = useState(false);
    // const [isPasswordReset, setIsPasswordReset] = useState(false);
    // const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    // const [isLockScreen, setIsLockScreen] = useState(false);
    // const [isLogout, setIsLogout] = useState(false);
    // const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    // const [isVerification, setIsVerification] = useState(false);
    // const [isError, setIsError] = useState(false);

    // // Pages
    // const [isProfile, setIsProfile] = useState(false);
    // const [isLanding, setIsLanding] = useState(false);


    // // Charts
    // const [isApex, setIsApex] = useState(false);

    // // Multi Level
    // const [isLevel1, setIsLevel1] = useState(false);
    // const [isLevel2, setIsLevel2] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');
    const [allowCreateOperator, setAllowCreateOperator] = useState(false);
    const [allowCreateJackpot, setAllowCreateJackpot] = useState(false);
    const [allowHR, setAllowHR] = useState(false);
    const [isOperator, setisOperatorc] = useState(false);



    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        async function fetchData() {
            await setAllowCreateOperator(await isAllowedToCreateOperator(parseInt(localStorage.getItem("opID"))))
            await setAllowCreateJackpot(await (parseInt(localStorage.getItem("opID"))==1))
            await setAllowHR([53].includes(parseInt(localStorage.getItem("opID"))))



          }
          fetchData();
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        // if (iscurrentState !== 'Apps') {
        //     setIsApps(false);
        // }
        // if (iscurrentState !== 'Auth') {
        //     setIsAuth(false);
        // }
        // if (iscurrentState !== 'Pages') {
        //     setIsPages(false);
        // }
        // if (iscurrentState !== 'BaseUi') {
        //     setIsBaseUi(false);
        // }
        // if (iscurrentState !== 'AdvanceUi') {
        //     setIsAdvanceUi(false);
        // }
        // if (iscurrentState !== 'Forms') {
        //     setIsForms(false);
        // }
        // if (iscurrentState !== 'Tables') {
        //     setIsTables(false);
        // }
        // if (iscurrentState !== 'Charts') {
        //     setIsCharts(false);
        // }
        // if (iscurrentState !== 'Icons') {
        //     setIsIcons(false);
        // }
        // if (iscurrentState !== 'Maps') {
        //     setIsMaps(false);
        // }
        // if (iscurrentState !== 'MuliLevel') {
        //     setIsMultiLevel(false);
        // }
        // if (iscurrentState === 'Widgets') {
        //     history("/widgets");
        //     document.body.classList.add('twocolumn-panel');
        // }
        // if (iscurrentState !== 'Landing') {
        //     setIsLanding(false);
        // }
    }, [
        history,
        iscurrentState,
        isDashboard,
// isOperator
        // isApps,
        // isAuth,
        // isPages,
        // isBaseUi,
        // isAdvanceUi,
        // isForms,
        // isTables,
        // isCharts,
        // isIcons,
        // isMaps,
        // isMultiLevel
    ]);

  
    const menuItems3 = [
        {
            id: "home",
            label: "Home",
            icon: "ri-home-8-line",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
   
        },
        {
            label: "Menu",
            isHeader: true,
        },
        { id: "shops", label: "Service Providers", icon: " ri-shopping-cart-2-fill",link: "/shops" },
        { id: "onlineusers", label: "Users", icon: "ri-file-user-line",link: "/onlineusers" },
        // { id: "operators", label: "Appointments", icon: " ri-record-circle-line",link: "/operators" },
        // { id: "jackpots", label: "Grand Jackpots", icon: "ri-trophy-line",link: "/jackpots" },
        // { id: "defaultJacks", label: "Jackpot Templates", icon: "ri-file-paper-2-line",link: "/jackpotTemplates" },
        // { id: "lobbies", label: "All Lobbies", icon: "ri-file-paper-line",link: "/lobbiesAll" },
        // { id: "demo", label: "Demo", icon: "ri-file-line",link: "/demo" },


        {
            label: "Create New",
            isHeader: true,
        },
        { id: "newShop", label: "New Provider", icon: " ri-add-fill",link: "/newShop" },
        {
            label: "TOOLS",
            isHeader: true,
        },

        {
            id: "apps2",
            label: "Top",
            icon: "ri-pie-chart-2-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsApps2(!isApps2);
                setIscurrentState('Apps2');
                updateIconSidebar(e);
            },
            stateVariables: isApps2,
            subItems: [
                {
                    id: "apps2chat",
                    label: "Top Providers",
                    link: "/topProvider",
                    parentId: "apps2",
                },
                    ]
                },


        {
            label: "ACCOUNT",
            isHeader: true,
        },
        { id: "Tickets", label: "Tickets", icon: " ri-ticket-2-fill",link: "/support" },
        // { id: "PROFILE", label: "Profile", icon: " ri-user-3-line",link: "/profile" },
        { id: "FAQ", label: "FAQ", icon: "ri-question-line",link: "/FAQ" },


    ];

    const menuItemsProv = [
        {
            id: "home",
            label: "Home",
            icon: "ri-home-8-line",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
   
        },
        {
            label: "Menu",
            isHeader: true,
        },
        { id: "calendar", label: "Calendar", icon: "ri-file-paper-line",link: "/calendar" },

        { id: "services", label: "Services", icon: "ri-file-paper-line",link: "/services" },
        { id: "reviews", label: "Reviews", icon: "ri-file-user-line",link: "/reviews" },
        { id: "staff", label: "Staff", icon: "ri-file-paper-line",link: "/staff" },

        // { id: "operators", label: "Appointments", icon: " ri-record-circle-line",link: "/operators" },
        // { id: "jackpots", label: "Grand Jackpots", icon: "ri-trophy-line",link: "/jackpots" },
        // { id: "defaultJacks", label: "Jackpot Templates", icon: "ri-file-paper-2-line",link: "/jackpotTemplates" },
        // { id: "lobbies", label: "All Lobbies", icon: "ri-file-paper-line",link: "/lobbiesAll" },
        // { id: "demo", label: "Demo", icon: "ri-file-line",link: "/demo" },


        {
            label: "Create New",
            isHeader: true,
        },
        { id: "newService", label: "New Service", icon: " ri-add-fill",link: "/newService" },
        { id: "newStaff", label: "New Staff", icon: " ri-add-fill",link: "/newStaff" },

        {
            label: "TOOLS",
            isHeader: true,
        },

        {
            id: "apps2",
            label: "Top",
            icon: "ri-pie-chart-2-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsApps2(!isApps2);
                setIscurrentState('Apps2');
                updateIconSidebar(e);
            },
            stateVariables: isApps2,
            subItems: [
                {
                    id: "apps2chat",
                    label: "Top Providers",
                    link: "/topProvider",
                    parentId: "apps2",
                },
                {
                    id: "apps2chat",
                    label: "Top Users",
                    link: "/topUsers",
                    parentId: "apps2",
                },
                {
                    id: "apps2chat",
                    label: "Top Services",
                    link: "/topServicesStaff",
                    parentId: "apps2",
                },
                    ]
                },


        {
            label: "ACCOUNT",
            isHeader: true,
        },
        { id: "Tickets", label: "Tickets", icon: " ri-ticket-2-fill",link: "/support" },
        { id: "PROFILE", label: "Profile", icon: " ri-user-3-line",link: "/profile" },
        { id: "FAQ", label: "FAQ", icon: "ri-question-line",link: "/FAQ" },


    ];
 

    return <React.Fragment>{menuItems3}</React.Fragment>;
};
export default Navdata;