import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Breadcrumb, BreadcrumbItem, Card, CardHeader, CardBody, Col, Container, Input, Label, Row, Button, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Link, useParams } from 'react-router-dom';
import classnames from "classnames";
import { useQuery, useMutation, gql } from '@apollo/client';
import { useCreateLog } from "../../Components/Common/functions";
import ServicesTab from '../Services/services';
import Reviews from '../Reviews/reviews';
import ProfilePage from '../GeneralShop/profile';
import ShopAppointments from '../ShopAppointments/shopAppointments';



const PROVIDER_QUERY = gql`
query MyQuery($id: Int!) {
  service_providers_by_pk(id: $id) {
    username
    password
    email
    description
    address_zip_code
    address_street
    address_state
    address_coordinates
    address_city
    phone
  }
}
`;

const EDIT_PROVIDER_MUTATION = gql`
mutation MyMutation($id: Int!, $username: String!, $password: String!, $email: String!, $description: String!, $address_zip_code: String!, $address_street: String!, $address_state: String!, $address_coordinates: String!, $address_city: String!, $phone: String!) {
  update_service_providers_by_pk(pk_columns: {id: $id}, _set: {username: $username, password: $password, email: $email, description: $description, address_zip_code: $address_zip_code, address_street: $address_street, address_state: $address_state, address_coordinates: $address_coordinates, address_city: $address_city, phone: $phone}) {
    id
  }
}
`;

const DELETE_PROVIDER_MUTATION = gql`
mutation MyMutation($id: Int!) {
  delete_service_providers_by_pk(id: $id) {
    id
  }
}
`;

const ServiceProviderDetails = () => {
  const { t } = useTranslation();
  const { createLog } = useCreateLog();
  const { userId } = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const [providerDetails, setProviderDetails] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [mutationSuccess, setMutationSuccess] = useState(false);
  const [modal, setModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Add this state variable

  const toggleModal = () => setModal(!modal);

  console.log('Component initialized');

  const { loading, error, data, refetch } = useQuery(PROVIDER_QUERY, {
    variables: { id: parseInt(userId) },
    onCompleted: (data) => {
      console.log('Data fetched:', data);
      setProviderDetails(data.service_providers_by_pk);
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    }
  });

  const [editProviderMutation] = useMutation(EDIT_PROVIDER_MUTATION, {
    onCompleted: (data) => {
      console.log('Edit mutation completed:', data);
      createLog(parseInt(userId), "service_provider", "Details Edited", true);
      setMutationSuccess(true);
    },
    onError: (error) => {
      console.error('Error in edit mutation:', error);
      createLog(parseInt(userId), "service_provider", "Details Edited", false);
    },
  });

  const [deleteProviderMutation] = useMutation(DELETE_PROVIDER_MUTATION, {
    onCompleted: (data) => {
      console.log('Delete mutation completed:', data);
      createLog(parseInt(userId), "service_provider", "Provider Deleted", true);
      window.location.href = "/#/serviceProviders";
    },
    onError: (error) => {
      console.error('Error in delete mutation:', error);
      createLog(parseInt(userId), "service_provider", "Provider Deleted", false);
    }
  });

  const handleSaveDetailsClick = async () => {
    const { username, password, email, description, address_zip_code, address_street, address_state, address_coordinates, address_city, phone } = providerDetails;
    if (!username || !password || !email || !description || !address_zip_code || !address_street || !address_state || !address_coordinates || !address_city || !phone) {
      alert("All fields are required.");
      return;
    }
    try {
      await editProviderMutation({
        variables: {
          id: parseInt(userId),
          username,
          password,
          email,
          description,
          address_zip_code,
          address_street,
          address_state,
          address_coordinates,
          address_city,
          phone,
        },
      });
      setMutationSuccess(true);
    } catch (error) {
      console.error('Error in handleSaveDetailsClick:', error);
    }
  };

  const handleDeleteProviderClick = async () => {
    try {
      await deleteProviderMutation({ variables: { id: parseInt(userId) } });
      toggleModal();
    } catch (error) {
      console.error('Error in handleDeleteProviderClick:', error);
    }
  };

  useEffect(() => {
    if (mutationSuccess) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      setMutationSuccess(false);
    }
  }, [mutationSuccess]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  console.log('Rendering component with details:', providerDetails);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Service Providers")} pageTitle={t("Home")} />
          {showSuccessMessage && (
            <div className="alert alert-success text-center" role="alert">
              Success!
            </div>
          )}
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">{t("Service Provider")} - {providerDetails.username}</h5>
                </CardHeader>
                <CardBody>
                  <Nav tabs className="nav-tabs mb-3">
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1" })} onClick={() => { toggle("1"); }}>
                        {t("Details")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2" })} onClick={() => { toggle("2"); }}>
                        {t("Services")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "3" })} onClick={() => { toggle("3"); }}>
                        {t("Appointments")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "4" })} onClick={() => { toggle("4"); }}>
                        {t("Reviews")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "5" })} onClick={() => { toggle("5"); }}>
                        {t("General")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                      <Card>
                        <CardBody>
                          <form action="#">
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="usernameInput" className="form-label">{t("Username")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input disabled type="text" className="form-control" id="usernameInput" value={providerDetails.username || ''} onChange={(e) => setProviderDetails({ ...providerDetails, username: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="passwordInput" className="form-label">{t("Password")}</Label>
                              </Col>
                              <Col lg={6}>
                                <div className="input-group">
                                  <Input type={showPassword ? "text" : "password"} className="form-control" id="passwordInput" value={providerDetails.password || ''} onChange={(e) => setProviderDetails({ ...providerDetails, password: e.target.value })} />
                                  <button type="button" className="btn btn-outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? "Hide" : "Show"}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="emailInput" className="form-label">{t("Email")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="email" className="form-control" id="emailInput" value={providerDetails.email || ''} onChange={(e) => setProviderDetails({ ...providerDetails, email: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="descriptionInput" className="form-label">{t("Description")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="descriptionInput" value={providerDetails.description || ''} onChange={(e) => setProviderDetails({ ...providerDetails, description: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="phoneInput" className="form-label">{t("Phone")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="phoneInput" value={providerDetails.phone || ''} onChange={(e) => setProviderDetails({ ...providerDetails, phone: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="coordinatesInputLat" className="form-label">{t("Coordinates")}</Label>
                              </Col>
                              <Col lg={3}>
                                <Input type="text" className="form-control" id="coordinatesInputLat" placeholder="Latitude" value={providerDetails.address_coordinates ? providerDetails.address_coordinates.split(',')[0] : ''} onChange={(e) => {
                                  const coords = providerDetails.address_coordinates ? providerDetails.address_coordinates.split(',') : ['', ''];
                                  coords[0] = e.target.value;
                                  setProviderDetails({ ...providerDetails, address_coordinates: coords.join(',') });
                                }} />
                              </Col>
                              <Col lg={3}>
                                <Input type="text" className="form-control" id="coordinatesInputLon" placeholder="Longitude" value={providerDetails.address_coordinates ? providerDetails.address_coordinates.split(',')[1] : ''} onChange={(e) => {
                                  const coords = providerDetails.address_coordinates ? providerDetails.address_coordinates.split(',') : ['', ''];
                                  coords[1] = e.target.value;
                                  setProviderDetails({ ...providerDetails, address_coordinates: coords.join(',') });
                                }} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressStreetInput" className="form-label">{t("Street Address")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressStreetInput" value={providerDetails.address_street || ''} onChange={(e) => setProviderDetails({ ...providerDetails, address_street: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressCityInput" className="form-label">{t("City")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressCityInput" value={providerDetails.address_city || ''} onChange={(e) => setProviderDetails({ ...providerDetails, address_city: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressStateInput" className="form-label">{t("State")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressStateInput" value={providerDetails.address_state || ''} onChange={(e) => setProviderDetails({ ...providerDetails, address_state: e.target.value })} />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={3}>
                                <Label htmlFor="addressZipCodeInput" className="form-label">{t("Zip Code")}</Label>
                              </Col>
                              <Col lg={6}>
                                <Input type="text" className="form-control" id="addressZipCodeInput" value={providerDetails.address_zip_code || ''} onChange={(e) => setProviderDetails({ ...providerDetails, address_zip_code: e.target.value })} />
                              </Col>
                            </Row>
                            <div className="text-end">
                              <Button type="button" className="btn btn-primary" onClick={handleSaveDetailsClick}>{t("Save")}</Button>
                            </div>
                          </form>
                        </CardBody>
                      </Card>
                      <div className="text-end">
                        <Button type="submit" className="btn btn-light" onClick={toggleModal}>{t("Delete this Provider")}</Button>
                        <Modal isOpen={modal} toggle={toggle}>
                          <ModalHeader toggle={toggleModal}>Confirm Deletion</ModalHeader>
                          <ModalBody>
                            Are you sure you want to delete this provider? This action cannot be undone.
                          </ModalBody>
                          <ModalFooter>
                            <Button color="primary" onClick={handleDeleteProviderClick}>Yes, Delete</Button>{' '}
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                    <ServicesTab serviceProviderId={parseInt(userId)}/>
                    </TabPane>
                    <TabPane tabId="4">
                    <Reviews serviceProviderId={parseInt(userId)}/>
                    </TabPane>
                    <TabPane tabId="5">
                    <ProfilePage serviceProviderId={parseInt(userId)}/>
                    </TabPane>
                    <TabPane tabId="3">
                    <ShopAppointments serviceProviderId={parseInt(userId)}/>
                    </TabPane>
                    
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServiceProviderDetails;