import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";import { Card,CardHeader, CardBody, Col, Container, Input, Label, Row, Table,Button,Modal, ModalBody, ModalHeader,ModalFooter, Spinner} from 'reactstrap';
//import { BasicTable, ScrollVertical, ScrollHorizontal, AlternativePagination, FixedHeaderDatatables, ModalDataDatatables, AjaxDatatables } from '../Tables/DataTables/datatableCom';
import { useQuery,useMutation, gql } from '@apollo/client';
// import checkTokenValidity from '../../Layouts/Footer'
import DataTable from 'react-data-table-component';


const APPOINTMENTS_QUERY = gql`
query GetAppointments($_eq: Int = 10) {
  appointments(
    order_by: {created_at: desc}
    where: {appointment_to_staff_to_service_relationship: {staff_to_service_relationship: {service: {service_provider_id: {_eq: $_eq}}}}}
  ) {
    id
    created_at
    start_time
    end_time
    status
    user {
      username
    }
    appointment_to_staff_to_service_relationship {
      staff_to_service_relationship {
        service {
          id
          name
          price
          service_provider {
            username
          }
        }
      }
    }
  }
}

`;










const ShopAppointments = ({serviceProviderId}) => {
  const { t } = useTranslation();
document.title ="Home";



const [isLoading, setIsLoading] = useState(true)
const [sortState, setSortState] = useState({});

const [appointments, setAppointments] = useState([]);








const { refetch } = 
useQuery(
    APPOINTMENTS_QUERY,

  {
    onError:(err) =>{
      console.log(err.message)
      // window.location.href = "/#/login"

    },
    variables:  {"_eq": parseInt(serviceProviderId)},

    onCompleted: async(data) => {
      console.log("TA DATA:",data)

        if (data && data.appointments) {
          setAppointments(data.appointments);
        }

      


      setIsLoading(false)

    },
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: 'network-only', // or 'cache-and-network'

    // pollInterval:5000
  }
);





useEffect(() => {
  let theDescendants=localStorage.getItem("descendants")
  let theUsername=localStorage.getItem("username")

  console.log("descendants",theDescendants)
  console.log("theUsername",theUsername)

}, []);

  const customStyles = {

    cells: {
      style: {
        border: '0.5px solid #dee2e6',
      },
    },
  };


const columns = [
  {
    name: t("Created At"),
    selector: row => new Date(row.created_at).toLocaleString(),
    sortable: true
  },
  {
    name: t("User"),
    selector: row =>row.user.username,
    sortable: true
  },
  {
    name: t("Service"),
    selector: (row) => {
      const services = row.appointment_to_staff_to_service_relationship.map(
        (rel) => rel.staff_to_service_relationship.service.name
      );

      // If there's more than one service, prepend "COMB: "
      return services.length > 1 ? `COMB: ${services.join(" + ")}` : services[0] || "N/A";
    },
    sortable: true,
  },
//   {
//     name: t("Provider"),
//     selector: row => 
//       row.appointment_to_staff_to_service_relationship[0]?.staff_to_service_relationship.service.service_provider.username || "N/A",
//     sortable: true
//   },
  {
    name: t("Price"),
    selector: row => {
      const price = row.appointment_to_staff_to_service_relationship[0]?.staff_to_service_relationship.service.price;
      return price ? `$${price.toFixed(2)}` : "N/A";
    },
    sortable: true
  },
  {
    name: t("Start Time"),
    selector: row => new Date(row.start_time).toLocaleString(),
    sortable: true
  },

  {
    name: t("Status"),
    selector: row => row.status,
    sortable: true
  }
];

const rowsPerPageSample={ rowsPerPageText: t('Rows per page:'), rangeSeparatorText: t('of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }

  return (
    <React.Fragment>      
        <Container fluid>

          <Row>


    
            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">{t("Appointments")}</h5>
                                </CardHeader>
                                <CardBody>

            <DataTable

            striped
            progressPending={isLoading}
            dense
            highlightOnHover
            columns={columns}
            data={appointments}
            paginationComponentOptions={rowsPerPageSample}

            pagination
            paginationRowsPerPageOptions={[10, 15, 20,100]}

            customStyles={customStyles}

        />
                                </CardBody>
                            </Card>
          </Row>
          

        </Container>


    </React.Fragment>
  );
};

export default ShopAppointments; 